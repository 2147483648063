<template>
  <div>
    <div class="loading" v-if="loading">
      <div class="loading-content">
        <img src="~@/assets/images/loading.svg" alt="Loading..." />
      </div>
    </div>
    <transition name="show-message-transition">
      <div v-if="message.state" :class="`${message.type}-message`">
        <div class="container">
          <div>
            <p>{{ message.message }}</p>
          </div>
        </div>
      </div>
    </transition>
    <div class="page-gradient">
      <section class="title container center">
        <h3>Get in Touch</h3>
        <p>We're ready to give you the best product in the market.</p>
      </section>
      <div class="bottom-shape">
        <img src="~@/assets/images/page-shape.png" alt="" />
      </div>
    </div>
    <div class="contact section-space">
      <div class="contact-wrapper container">
        <div class="contact-info flex">
          <div class="contact-box">
            <img src="~@/assets/images/contact.svg" alt="Email us!" />
            <p>Email Address:</p>
            <span>info@exclusiveuxdesigns.com</span>
          </div>
          <div class="contact-box">
            <img src="~@/assets/images/call.svg" alt="Email us!" />
            <p>Phone Number:</p>
            <span>+1 (786) 312 0625</span>
          </div>
          <div class="contact-box">
            <img src="~@/assets/images/clock.svg" alt="Email us!" />
            <p>Business Hours:</p>
            <span>Mon - Sat: 8:00 AM - 6:00 PM</span>
          </div>
        </div>
        <Form @submit="onSubmit" :validation-schema="schema">
          <div class="flex">
            <TextInput
              class="input-wrapper"
              name="name"
              type="text"
              label="Full Name:"
              placeholder="John Doe"
            />
            <TextInput
              class="input-wrapper"
              name="email"
              type="email"
              label="Email Address:"
              placeholder="email@yourcompany.com"
            />
          </div>
          <div class="input-wrapper">
            <label>I'm interested in:</label>
            <Field name="interested" as="select">
              <option value="default" selected disabled>
                Choose one of the following services
              </option>
              <option value="Web Design & Development">
                Web Design & Development
              </option>
              <option value="App Design & Development">
                App Design & Development
              </option>
              <option value="Back-End Development">Back-End Development</option>
              <option value="Digital Marketing (SEO, Email Marketing, etc..)">
                Digital Marketing (SEO, Email Marketing, etc..)
              </option>
              <option value="Other">Other</option>
            </Field>
          </div>
          <div class="input-wrapper flex-column">
            <TextArea
              name="comments"
              label="Comments:"
              placeholder="Talk about your project..."
            />
          </div>
          <div class="form-btn flex-end">
            <button
              type="submit"
              name="button"
              class="btn-style txt-white blue"
            >
              Send Message
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from 'vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import TextInput from '@/components/Base/TextInput.vue'
import TextArea from '@/components/Base/TextArea.vue'
// import axios from 'axios'
export default {
  name: 'Contact',
  components: {
    TextInput,
    TextArea,
    Form,
    Field
  },
  setup () {
    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const loading = ref(false)
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string()
        .email()
        .required(),
      comments: Yup.string()
        .min(20)
        .required(),
      interested: Yup.string()
        .test(
          'default',
          'Choose one of the services in select',
          value => value !== 'default'
        )
        .required()
    })

    const message = reactive({
      state: false,
      type: '',
      message: ''
    })
    const onSubmit = async (values, { resetForm }) => {

      try {
        loading.value = true
        setTimeout(() => {
          message.state = true
          message.type = 'success'
          message.message = 'Thank you for contacting us! We appreciate that you’ve taken the time to write us.'
          resetForm()
          loading.value = false
        }, 2000)
        setTimeout(() => {
          message.state = false
        }, 5000)
        const res = await fetch(
          'https://www.exclusiveuxdesigns.com/mail.php',
          {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(values) // body data type must match "Content-Type" header
          }
        )
      } catch (error) {
        loading.value = false
        console.log(error)
      }
    }

    return {
      onSubmit,
      schema,
      loading,
      message
    }
  }
}
</script>
<style scoped>
@import '../assets/css/contact.css';
</style>
